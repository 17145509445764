export const environment = {
  production: true,
  fullStoryId: null,
  apiurl: 'https://sandbox.roundtriplabs.com/api/v1/admin',
  apiv3url: 'https://sandbox.roundtriplabs.com/api/v3',
  appurl: 'https://sandbox.roundtriplabs.com/',
  googleApiKey: 'AIzaSyBayUtAB9yiro6fV_f98_MMt05b0dWUaCU',
  sqs_queue_url: 'https://sqs.us-east-1.amazonaws.com/146263503364/roundtrip-webhooks-v2-sandbox.fifo',
  launchDarklyClientApiKey: '6115347153dd8e23b9910a09',
  auth0ClientId: 'Fjw9CvPtPR0IUdgynkaTxkrgCvw5Zyev',
  auth0Domain: 'login-sandbox.rideroundtrip.com',
  adminCreation: 'EVERYONE'
};
