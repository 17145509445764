<ol class="breadcrumb">
  <li><a routerLink="/hospitals">Organizations</a></li>
  <li class="active">{{hospital.name}}</li>
</ol>

<h1>{{hospital.name}}</h1>

<tabset #tabset>
  <tab (select)="tabChanged($event)">
    <ng-template tabHeading>
      Details
    </ng-template>

    <div *ngIf="!editingHospital">
      <table class="table table-striped">
        <tbody>
          <tr>
            <th class="width-250">ID</th>
            <td>{{hospital.id}}</td>
          </tr>

          <tr>
            <th>Name</th>
            <td>{{hospital.name}}</td>
          </tr>

          <tr *ngIf="hospital.customer_id">
            <th>Customer</th>
            <td>
              <a [routerLink]="['/customers', hospital.customer_id]">{{hospital.customer_name}}</a>
            </td>
          </tr>

          <tr *ngIf="hospital.parent_id">
            <th>Parent Organization</th>
            <td>
              <a [routerLink]="['/hospitals', hospital.parent_id]">{{parentHospitalName}}</a>
            </td>
          </tr>

          <tr>
            <th>Organization Type</th>
            <td>{{organizationTypesMap[hospital.organization_type]}}</td>
          </tr>

          <tr>
            <th>Created On</th>
            <td>{{hospital.created_at | date}}</td>
          </tr>

          <tr>
            <th>Updated On</th>
            <td>{{hospital.updated_at | date}}</td>
          </tr>

          <tr>
            <th>Advanced Notice for Ride Share Rides</th>
            <td>
              <span>{{hospital.advance_notice_for_ride_share_rides}} (minutes)</span>
              <p class="small">
                The Advanced Notice setting for Ride Share rides lets us know how far in advance we should request a Ride Share ride for patients. For a large city we recommend the default time period of 5 minutes. For suburban and rural areas we recommend changing this to at least 15 minutes.
              </p>
            </td>
          </tr>

          <tr>
            <th>Client Specific Service Phone Number</th>
            <td>
              <span>{{hospital.service_phone_number}}</span>
            </td>
          </tr>

          <tr>
            <th>Payer Types</th>
            <td>
              <span *ngFor="let item of selectedPayerTypes; let $last=last">{{ item.itemName }}<span *ngIf="!$last">, </span></span>
            </td>
          </tr>

          <tr>
            <th>Password Expiration</th>
            <td>
              <span>{{hospital.password_expiration_interval}} {{ hospital.password_expiration_interval_type }}(s)</span>
            </td>
          </tr>

          <tr>
            <th>User Session Timeout</th>
            <td>
              <span *ngIf="hospital.user_timeout_interval">{{ hospital.user_timeout_interval }} (minutes)</span>
              <span *ngIf="!hospital.user_timeout_interval">-</span>
            </td>
          </tr>

          <tr>
            <th>Enabled Vehicles</th>
            <td>
              <span *ngFor="let item of selectedVehicles; let $last=last">{{ item.itemName }}<span *ngIf="!$last">, </span></span>
            </td>
          </tr>

          <tr>
            <th>Unit of Measurement</th>
            <td>{{measurementUnitMap[hospital.measurement_unit]}}</td>
          </tr>

          <tr>
            <th>
              Trip Reasons
              <a routerLink="/reason-types" class="reason-type-manage-link">
                Manage
              </a>
            </th>
            <td>
              <span *ngFor="let item of selectedReasonTypes; let $last=last">{{ item.itemName }}<span *ngIf="!$last">, </span></span>
              <p class="small">
                Trip reasons removed here might affect geofencing rules. Please check before continuing.
              </p>
            </td>
          </tr>

          <tr>
            <th>Email Offer Text</th>
            <td>
              <pre>{{hospital.email_offer_text}}</pre>
            </td>
          </tr>

          <tr>
            <th>Send Will Call Ready Text?</th>
            <td>
              <ui-switch id="will_call" name="will_call" [(ngModel)]="hospital.send_will_call_text" disabled="true">
              </ui-switch>
            </td>
          </tr>

          <tr>
            <th>Address</th>
            <td>{{hospital.address}}</td>
          </tr>

          <tr>
            <th>Tax ID</th>
            <td>{{hospital.tax_id}}</td>
          </tr>

          <tr>
            <th>Community Settings</th>
            <td>
              <p>Preferred Logic: <span>{{ ppStrategyDisplayNames[hospital.pp_strategy] }}</span></p>
              <p>Access Rules: <span>{{ accessStrategyDisplayNames[hospital.provider_access_strategy] }}</span></p>
            </td>
          </tr>

          <tr>
            <th>Disabled PCS?</th>
            <td>
              <ui-switch id="pcs_disabled" name="pcs_disabled" [(ngModel)]="hospital.pcs_disabled" disabled="true">
              </ui-switch>
            </td>
          </tr>

          <tr *ngIf="hospital.children.length == 0">
            <th>Advanced Ride Cancelation?</th>
            <td>
              <ui-switch id="advanced_cancelation_enabled" name="advanced_cancelation_enabled" [(ngModel)]="hospital.advanced_cancelation_enabled" disabled="true">
              </ui-switch>
            </td>
          </tr>

          <tr *ngIf="hospital.advanced_cancelation_enabled">
            <th>Ride Cancel Threshold</th>
            <td>
              {{hospital.advanced_cancelation_threshold}} hours
            </td>
          </tr>

          <tr *ngIf="hospital.advanced_cancelation_enabled">
            <th>Advanced Ride Cancelation Reason</th>
            <td>
              {{hospital.advanced_cancelation_reason}}
            </td>
          </tr>

          <tr *ngIf="hospital.children.length == 0">
            <th>Advanced Calling Transport ZD Ticket</th>
            <td>
              <ui-switch id="calling_providers_ticket_enabled" name="calling_providers_ticket_enabled" [(ngModel)]="hospital.calling_providers_ticket_enabled" disabled="true">
              </ui-switch>
            </td>
          </tr>

          <tr *ngIf="hospital.calling_providers_ticket_enabled && hospital.children.length == 0">
            <th>Trigger before ride start time</th>
            <td>
              {{hospital.calling_providers_ticket_threshold}} hours
            </td>
          </tr>

          <tr>
            <th>
              Authorizations not by Roundtrip
              <small class="display-block">(Redox-integrated)</small>
            </th>
            <td>
              <ui-switch id="auth_disabled"
                name="auth_disabled"
                [(ngModel)]="hospital.auth_disabled"
                disabled="true">
              </ui-switch>
            </td>
          </tr>

          <tr>
            <th>Allow Rider Creation</th>
            <td>
              <ui-switch id="allow_rider_creation"
                name="allow_rider_creation"
                [(ngModel)]="hospital.allow_rider_creation"
                disabled="true">
              </ui-switch>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="text-left">
          <button class="btn btn-primary" (click)="editHospital()">Edit</button>
      </div>
    </div>

    <div *ngIf="editingHospital">
      <div class="row">
        <form class="form-horizontal col col-sm-6" [formGroup]="hospitalEditForm" >
          <div class="form-group">
            <label class="col-sm-5 control-label text-left">ID</label>
            <div class="col-sm-7">
              <input class="form-control" disabled type="text" formControlName="id">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-5 control-label text-left">Name</label>
            <div class="col-sm-7">
              <input class="form-control" type="text" formControlName="name">
              <div class="form-error" *ngIf="hospitalEditForm.get('name').invalid">
                <p>Name cannot be empty.</p>
              </div>
            </div>
          </div>
          <ng-container>
            <div class="form-group">
              <label class="col-sm-5 control-label text-left">Customer</label>
              <div class="col-sm-7">
                <select class="form-control" formControlName="customer_id">
                  <option [ngValue]="null">None</option>
                  <option *ngFor="let customer of customers" [value]="customer.id">
                    {{customer.name}}
                  </option>
                </select>
              </div>
            </div>
          </ng-container>
          <ng-container>
            <div class="form-group">
              <label class="col-sm-5 control-label text-left">Parent Organization</label>
              <div class="col-sm-7">
                <select class="form-control" formControlName="parent_id">
                  <option [ngValue]="null">None</option>
                  <option *ngFor="let hospital of parentHospitals" [value]="hospital.id">
                    {{hospital.name}}
                  </option>
                </select>
              </div>
            </div>
          </ng-container>
          <ng-container>
            <div class="form-group">
              <label class="col-sm-5 control-label text-left">Organization Type</label>
              <div class="col-sm-7">
                <select class="form-control" formControlName="organization_type">
                  <option *ngFor="let type of organizationTypeOptions" [value]="type.id">
                    {{type.name}}
                  </option>
                </select>
                <div class="form-error" *ngIf="hospitalEditForm.get('organization_type') && hospitalEditForm.get('organization_type').invalid">
                  <p>Organization type cannot be empty.</p>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="form-group">
            <label class="col-sm-5 control-label text-left">Created On</label>
            <div class="col-sm-7">
              <input class="form-control" disabled type="text" formControlName="created_at">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-5 control-label text-left">Updated On</label>
            <div class="col-sm-7">
              <input class="form-control" disabled type="text" formControlName="updated_at">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-5 control-label text-left">Advanced Notice for Ride Share Rides</label>
            <div class="col-sm-7">
              <input class="form-control" type="number" formControlName="advance_notice_for_ride_share_rides">
              <div class="form-error" *ngIf="hospitalEditForm.get('advance_notice_for_ride_share_rides').invalid">
                <p>Notice period cannot be empty.</p>
              </div>
              <p class="small">
                The Advanced Notice setting for Ride Share rides lets us know how far in advance we should request a Ride Share ride for patients. For a large city we recommend the default time period of 5 minutes. For suburban and rural areas we recommend changing this to at least 15 minutes.
              </p>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-5 control-label text-left">Client Specific Service Phone Number</label>
            <div class="col-sm-7">
              <input class="form-control" type="text" formControlName="service_phone_number">
              <div class="form-error" *ngIf="hospitalEditForm.get('service_phone_number').invalid">
                <p>Service phone number cannot be empty.</p>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-5 control-label text-left">Payer Types</label>
            <div class="col-sm-7">
              <angular2-multiselect
                formControlName="payer_types"
                [data]="payerTypesList"
                [settings]="payerTypeSettings"
                (onDeSelect)="onPayerItemDeSelect($event)"
                (onDeSelectAll)="onPayerDeSelectAll()"
              >
                <c-item>
                  <ng-template let-item="item">
                    <label [ngClass]="{'disabled-option': item['required']}">{{item.itemName}}</label>
                  </ng-template>
                </c-item>
              </angular2-multiselect>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-5 control-label text-left">Password Expiration</label>
            <div class="col-sm-7">
              <div class="row">
                <div class="col col-sm-6">
                  <input class="form-control" type="number" placeholder="0 (in minutes)" formControlName="password_expiration_interval">
                </div>
                <div class="col col-sm-6">
                  <select class="form-control" formControlName="password_expiration_interval_type">
                    <option value="day">Day(s)</option>
                    <option value="week">Week(s)</option>
                    <option value="year">Year(s)</option>
                  </select>
                </div>
                <div class="col col-sm-12 form-error" *ngIf="hospitalEditForm.get('password_expiration_interval').invalid ||
                hospitalEditForm.get('password_expiration_interval_type').invalid">
                  <p>Invalid expiration period.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-5 control-label text-left">User Session Timeout</label>
            <div class="col-sm-7">
              <input class="form-control" type="number" formControlName="user_timeout_interval">
              <div class="form-error" *ngIf="hospitalEditForm.get('user_timeout_interval').invalid">
                <p *ngIf="hospitalEditForm.get('user_timeout_interval').errors?.min">The value should be greater than or equal to 15.</p>
                <p *ngIf="hospitalEditForm.get('user_timeout_interval').errors?.max">The value should be less than or equal to 480.</p>
              </div>
              <p class="small">
                Please enter a value in minutes.
              </p>
            </div>
          </div>

          <div class="form-group">
            <label for="name" class="col-sm-5 control-label text-left">Enabled Vehicles</label>
            <div class="col-sm-7">
              <angular2-multiselect [data]="vehicleList" [(ngModel)]="selectedVehicles"
                                    [settings]="vehicleDropDownSettings"
                                    (onSelect)="onVehicleItemSelect($event)"
                                    (onDeSelect)="OnVehicleItemDeSelect($event)"
                                    formControlName="enabled_vehicles"
                                    >
              </angular2-multiselect>
              <div class="form-error" *ngIf="hospitalEditForm.get('enabled_vehicles').dirty && hospitalEditForm.get('enabled_vehicles').invalid">
                <p>Please select at least one option.</p>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-5 control-label text-left">Unit of Measurement</label>
            <div class="col-sm-7">
              <select class="form-control" formControlName="measurement_unit">
                <option *ngFor="let type of measurementUnitOptions" [value]="type.id">
                  {{type.name}}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-5 control-label text-left">Trip Reasons</label>
            <div class="col-sm-7">
              <angular2-multiselect [data]="reasonTypesList" [(ngModel)]="selectedReasonTypes"
                                    [settings]="reasonTypesDropDownSettings"
                                    (onSelect)="onReasonItemSelect($event)"
                                    (onDeSelect)="OnReasonItemDeSelect($event)"
                                    formControlName="reason_types">
              </angular2-multiselect>
              <div class="form-error" *ngIf="hospitalEditForm.get('reason_types').dirty && hospitalEditForm.get('reason_types').invalid">
                <p>Please select at least one option.</p>
              </div>
              <p class="small">
                Trip reasons removed here might affect geofencing rules. Please check before continuing.
              </p>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-5 control-label text-left">Email Offer Text</label>
            <div class="col-sm-7">
              <textarea class="form-control" formControlName="email_offer_text" rows="5" placeholder="You are eligible for two free rides through the Facility program.">
              </textarea>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-5 control-lable">Send Will Call Ready Text?</label>
            <div class="col-sm-7">
              <ui-switch id="will_call" name="will_call" formControlName="send_will_call_text"></ui-switch>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-5 control-label text-left">Address</label>
            <div class="col-sm-7">
              <input class="form-control" type="text" formControlName="address">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-5 control-label text-left">Tax ID</label>
            <div class="col-sm-7">
              <input class="form-control" type="text" formControlName="tax_id">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-5 control-lable">Disabled PCS?</label>
            <div class="col-sm-7">
              <ui-switch id="pcs_disabled" name="pcs_disabled" formControlName="pcs_disabled"></ui-switch>
            </div>
          </div>
          <div class="form-group" *ngIf="hospital.children.length === 0">
            <label class="col-sm-5 control-lable">Advanced Ride Cancelation?</label>
            <div class="col-sm-7">
              <ui-switch id="advanced_cancelation_enabled" name="advanced_cancelation_enabled" formControlName="advanced_cancelation_enabled">
              </ui-switch>
            </div>
          </div>
          <div class="form-group" *ngIf="hospitalEditForm.get('advanced_cancelation_enabled') && hospitalEditForm.get('advanced_cancelation_enabled').value">
            <label class="col-sm-5 control-label text-left">Ride Cancel Threshold</label>
            <div class="col-sm-7">
              <input class="form-control" type="number" formControlName="advanced_cancelation_threshold">
              <div class="form-error"
              *ngIf="hospitalEditForm.get('advanced_cancelation_threshold').invalid &&
                hospitalEditForm.get('advanced_cancelation_threshold').errors.required">
                <p>Ride cancelation threshold cannot be empty.</p>
              </div>
              <div class="form-error"
              *ngIf="hospitalEditForm.get('advanced_cancelation_threshold').invalid &&
                !hospitalEditForm.get('advanced_cancelation_threshold').errors.required">
                <p>Ride cancelation threshold should be in between 1 and 72 hours.</p>
              </div>
              <p class="small">
                Please enter a value in hours.
              </p>
            </div>
          </div>
          <div class="form-group" *ngIf="hospitalEditForm.get('advanced_cancelation_enabled') && hospitalEditForm.get('advanced_cancelation_enabled').value">
            <label class="col-sm-5 control-label text-left">Advanced Ride Cancelation Reason</label>
            <div class="col-sm-7">
              <input class="form-control" type="text" formControlName="advanced_cancelation_reason">
              <div class="form-error"
              *ngIf="hospitalEditForm.get('advanced_cancelation_reason').touched &&
                hospitalEditForm.get('advanced_cancelation_reason').invalid">
                <p>Advanced ride cancelation reason cannot be empty.</p>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="hospital.children.length === 0">
            <label class="col-sm-5 control-lable">Advanced Calling Transport ZD Ticket</label>
            <div class="col-sm-7">
              <ui-switch id="calling_providers_ticket_enabled" name="calling_providers_ticket_enabled" formControlName="calling_providers_ticket_enabled">
              </ui-switch>
            </div>
          </div>
          <div class="form-group" *ngIf="hospitalEditForm.get('calling_providers_ticket_enabled').value && hospital.children.length == 0">
            <label class="col-sm-5 control-label text-left">Trigger before ride start time</label>
            <div class="col-sm-7">
              <input class="form-control" type="number" formControlName="calling_providers_ticket_threshold">
              <div *ngIf="hospitalEditForm.get('calling_providers_ticket_threshold').invalid">
                <div class="form-error"
                *ngIf="hospitalEditForm.get('calling_providers_ticket_threshold').errors.required">
                  <p>Trigger before ride start time cannot be empty.</p>
                </div>
                <div class="form-error"
                *ngIf="!hospitalEditForm.get('calling_providers_ticket_threshold').errors.required">
                  <p *ngIf="hospitalEditForm.get('calling_providers_ticket_threshold').errors?.min">
                    Trigger before ride start time should be greater than or equal to 0 hours.
                  </p>
                  <p *ngIf="hospitalEditForm.get('calling_providers_ticket_threshold').errors?.max">
                    Trigger before ride start time should be less than or equal to 96 hours.
                  </p>
                </div>
              </div>
              <p class="small">
                Please enter a value in hours.
              </p>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-5 control-lable">
              Authorizations not by Roundtrip
              <small class="display-block">(Redox-integrated)</small>
            </label>
            <div class="col-sm-7">
              <ui-switch id="auth_disabled"
                name="auth_disabled"
                formControlName="auth_disabled">
              </ui-switch>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-5 control-lable">Allow Rider Creation</label>
            <div class="col-sm-7">
              <ui-switch id="allow_rider_creation"
                name="allow_rider_creation"
                formControlName="allow_rider_creation">
              </ui-switch>
            </div>
          </div>
          <div class="action-wrap text-left">
            <button type="button" class="btn btn-default" (click)="cancelEditing()">Close</button>
            <button type="button" class="btn btn-primary" [disabled]="!hospitalEditForm.valid" (click)="update()">Update</button>
          </div>

        </form>
      </div>
    </div>

  </tab>

  <tab (select)="tabChanged($event)">
    <ng-template tabHeading>
      Vehicle Rates
    </ng-template>

    <app-vehicle-rates [hospital]="hospital" (vehicleRatesUpdated)="vehicleRatesUpdate($event)"></app-vehicle-rates>
  </tab>

  <tab (select)="tabChanged($event)">
    <ng-template tabHeading>
      Network
    </ng-template>

    <ul class="list-group">
      <li class="list-group-item" [ngClass]="{'active': isHospitalChild(h)}" (click)="toggleHospitalChild(h)"
        *ngFor="let h of hospitals">
        <span class="badge" *ngIf="isHospitalChild(h)">
          <i class="fa fa-check"></i>
        </span>
        {{h.name}}
      </li>
    </ul>
  </tab>

  <tab (select)="tabChanged($event)">
    <ng-template tabHeading>
      Programs
    </ng-template>

    <tabset>
      <tab heading="Active Programs" (select)="tabChanged($event)">
        <ul class="list-group" *ngIf="!hospitalPrograms.length">
          No Attached Programs Found
        </ul>
        <ul class="list-group" *ngIf="hospitalPrograms.length">
          <li class="list-group-item" *ngFor="let program of hospitalPrograms" (click)="updateProgram(program)">
            {{ program.name }}
          </li>
        </ul>
        <button class="btn btn-primary" (click)="addProgram()">
          <app-icon icon="book" iconClass="button-icon"></app-icon> Add Program
        </button>
      </tab>
      <tab heading="Inactive Programs" (select)="tabChanged($event)">
        <ul class="list-group" *ngIf="!deactivatedHospitalPrograms.length">
          No Inactive Programs Found
        </ul>
        <ul class="list-group" *ngIf="deactivatedHospitalPrograms.length">
          <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let program of deactivatedHospitalPrograms">
            {{ program.name }}
            <button class="btn btn-secondary btn-sm" (click)="restoreProgram(program)">Restore</button>
          </li>
        </ul>
      </tab>
    </tabset>    
  </tab>

  <tab>
    <ng-template tabHeading>
      Locations
    </ng-template>

    <ul class="list-group" *ngIf="!hospitalLocations.length">
      No Attached Locations Found
    </ul>

    <ng2-smart-table *ngIf="hospitalLocations && hospitalLocations.length"
      [settings]="locationTableConfig"
      [source]="hospitalLocations"
      (userRowSelect)="addLocation($event)">
    </ng2-smart-table>

    <button class="btn btn-primary" (click)="addLocation($event)">
      <app-icon icon="book" iconClass="button-icon"></app-icon> Add Locations
    </button>
  </tab>

  <tab>
    <ng-template tabHeading>
      TigerConnect Integration
    </ng-template>
    <app-tiger-connect-integration [hospital]="hospital"></app-tiger-connect-integration>
  </tab>

  <tab (select)="tabChanged($event)">
    <ng-template tabHeading>
      Community Settings
    </ng-template>

    <app-community-settings></app-community-settings>
  </tab>

  <tab (select)="tabChanged($event)">
    <ng-template tabHeading>
      Geofence
    </ng-template>

    <app-geofence [geofence]="geofence" [hospitalId]="hospital.id"></app-geofence>
  </tab>

  <tab (select)="tabChanged($event)">
    <ng-template tabHeading>
      User Upload
    </ng-template>

    <app-user-upload [hospitalId]="hospital.id"></app-user-upload>
  </tab>

  <tab (select)="tabChanged($event)">
    <ng-template tabHeading>
      Integrations
    </ng-template>

    <app-integrations [hospital]="hospital"></app-integrations>
  </tab>
  <tab (select)="tabChanged($event)">
    <ng-template tabHeading>
      Benefit Management
    </ng-template>

    <app-benefit-management [hospital]="hospital"></app-benefit-management>
  </tab>
  <tab (select)="tabChanged($event)" *ngIf="hospital.parent_id === null">
    <ng-template tabHeading>
      Quicksight Reports
    </ng-template>

    <app-quicksight-reports [hospital]="hospital"></app-quicksight-reports>
  </tab>
</tabset>
